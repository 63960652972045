@import "../../styles/default-styles";

.chatroom-user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;

  .profile-image-container {
    overflow: hidden;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    align-self: center;

    .profile-image {
      width: 100%;
      height: 100%;
    }
  }

  .admin-tag {
    color: $greenish-blue;
  }

  .delete-icon {
    cursor: pointer;
  }
}

.line {
  width: 100%;
  height: 1px;
  background: #616061;
}
