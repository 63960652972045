@import "../../styles/default-styles";

.chatroom-settings {
  min-width: 320px;
  height: 100%;
  box-shadow: -1px 0 5px rgba(0, 0, 0, 0.2);
  background: white;
  z-index: 5;
  overflow-y: scroll;

  .settings-heading {
    width: 100%;
    height: 10%;
    padding: 18px 20px;
    background: #f3f3f3;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .cross,
    .delete-icon {
      height: 40px;
      width: 40px;
      cursor: pointer;
    }

    .settings-heading-title {
      font-size: 22px;
      justify-self: center;
    }

    & svg {
      height: 100%;
      width: 100%;
    }
  }

  .controlls {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    .title {
      color: $primary-blue;
    }

    .edit {
      width: 1.5em;
      height: 1.5em;
      cursor: pointer;
      & svg {
        height: 100%;
        width: 100%;
      }
    }
  }

  .chatroom-description,
  .chatroom-users {
    width: 100%;
    padding: 18px 20px;
  }
}
