.message-input {
  width: 100%;
  height: 10%;

  form {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .input {
      height: 60%;
      width: 50%;
      border: none;
      border-radius: 50px;
      padding: 5px 20px;
      font-size: 20px;

      &:focus {
        outline: none;
      }
    }

    button,
    .set-meeting {
      outline: none;
      border: none;
      background: transparent;
      cursor: pointer;
      height: 60%;

      svg {
        height: 100%;
      }
    }

    .set-meeting {
    }
  }
}

.meeting-maker {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%);
  width: 300px;
  height: 330px;
  background: white;
  border-radius: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 17px;

    .time,
    .date {
      font-size: 20px;
    }

    input {
      height: 60%;
      width: 90%;
      border: none;
      border-radius: 50px;
      padding: 5px 20px;
      text-align: center;
      box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
      margin-top: 7px;

      &:focus {
        outline: none;
      }
    }
  }

  div.set-meeting-button {
    display: block;
    background: rgba(0, 0, 0, 0.2);
    padding: 5px;
    cursor: pointer;
    border-radius: 7px;
  }
}
