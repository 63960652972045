@import "../../styles/default-styles";

.box .profile-image-container {
  overflow: hidden;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  align-self: flex-start;

  .profile-image {
    width: 100%;
    height: 100%;
  }
}

.message-content {
  padding: 10px 15px;
  border-radius: 25px;
  color: white;
  max-width: 40vw;
  overflow-wrap: break-word;
  margin: 0 15px;
}

.right-box {
  align-self: flex-end;
  margin: 10px 0;

  display: flex;

  .message-content {
    background: $greenish-blue;
  }

  .meeting-container {
    background: $primary-blue;
  }
}

.left-box {
  align-self: flex-start;
  margin: 10px 0;

  display: flex;

  .message-content {
    background: $primary-blue;
  }

  .meeting-container {
    background: $greenish-blue;
  }
}

.meeting-box {
  width: 220px;
  height: 260px;
  padding: 0;
  overflow: hidden;

  box-shadow: 0 5px 8px rgba($color: black, $alpha: 0.3);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  * {
    color: white;
    text-align: center;
  }

  .meeting-heading {
    font-size: 25px;
    text-align: center;
    padding: 10px 0;
  }

  .meeting-container {
    border-radius: 25px;
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 8px;
    align-items: center;

    .date-container {
      .date-title {
        font-weight: bold;
      }
      .value {
        background: rgba($color: black, $alpha: 0.2);
        padding: 5px 18px;
        border-radius: 15px;
      }
    }
    .meeting-icon {
      height: 40px;
      width: 40px;

      svg {
        height: 100%;
        width: 100%;
      }
    }
  }
}
