.chatbox-header {
  width: 100%;
  height: 10%;
  padding: 18px 20px;
  background: #f3f3f3;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .chatbox-title {
    font-size: 25px;
    font-weight: 500;
  }

  svg {
    width: 35px;
    height: 35px;
    cursor: pointer;
  }
}
