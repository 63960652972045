.sidebar-bottom-section {
  width: 100%;
  height: 90%;

  .chatrooms-container {
    width: 100%;
    height: 100%;
    padding: 5px 0;
  }
}
